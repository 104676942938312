import React, { FC } from 'react';
import { QueryClient, QueryClientProvider, useIsFetching } from 'react-query';
import 'twin.macro';

import { ConfigProvider } from './ConfigContext';
import { GlobalStyles, StylesBase } from './StylesBase';
import Progress from './Progress';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 1000 * 10, refetchInterval: 1000 * 30 },
  },
});

const Layout: FC = ({ children, ...props }) => {
  const isLoading = useIsFetching({ stale: true });

  return (
    <>
      <GlobalStyles />
      <StylesBase />
      {isLoading > 0 && <Progress tw="absolute w-full" />}
      <div tw="pb-4 pt-6 text-gray-800" {...props}>
        <main>{children}</main>
      </div>
    </>
  );
};

export default ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <Layout>{children}</Layout>
    </ConfigProvider>
  </QueryClientProvider>
);
