import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as TwGlobalStyles } from 'twin.macro';

export const GlobalStyles = () => <TwGlobalStyles />;

export const StylesBase = createGlobalStyle`
p {
  ${tw`mb-4`}
}
a {
  ${tw`text-blue-600 underline hover:no-underline`}
}
`;
