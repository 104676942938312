import { useLocation } from '@reach/router';
import query, { ParsedQuery } from 'query-string';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createContext } from 'react';

export interface ConfigContext {
  spreadsheetId?: string;
  setSpreadsheetId: Dispatch<SetStateAction<string>>;
  activityRange?: string;
  setActivityRange: Dispatch<SetStateAction<string>>;
  legendRange?: string;
  setLegendRange: Dispatch<SetStateAction<string>>;
  bodyRange?: string;
  setBodyRange: Dispatch<SetStateAction<string>>;
  name?: string;
  setName: Dispatch<SetStateAction<string>>;
  birthDate?: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
  gender?: string;
  setGender: Dispatch<SetStateAction<string>>;
  schemaWidth: string;
  setSchemaWidth: Dispatch<SetStateAction<string>>;
}

export const ConfigContext = createContext<ConfigContext>({} as ConfigContext);

const ls = <T extends string>(
  q: ParsedQuery,
  key: string,
  initialValue = undefined,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(
    q[key] || (typeof window !== `undefined` && localStorage.getItem(key)) || initialValue,
  );

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }, [value]);

  return [value, setValue];
};

export const ConfigProvider = ({ children }) => {
  const location = useLocation();
  const q = query.parse(location.search);

  const [spreadsheetId, setSpreadsheetId] = ls(q, 'sheetId', '1rFwCQZhEa16xZyIZqIi6b6bWKreJvI4AGBh5Db_ZsjM');
  const [activityRange, setActivityRange] = ls(q, 'activityRange', 'Activities!8:104');
  const [bodyRange, setBodyRange] = ls(q, 'bodyRange', 'Body!B4:E9999');
  const [legendRange, setLegendRange] = ls(q, 'legendRange', 'Activities!B4:F6');
  const [name, setName] = ls(q, 'name', 'My baby');
  const [birthDate, setBirthDate] = ls(q, 'birthDate');
  const [gender, setGender] = ls(q, 'gender', 'boy');
  const [schemaWidth, setSchemaWidth] = ls<'default' | 'wide'>(q, 'schemaWidth', 'default');

  return (
    <ConfigContext.Provider
      value={{
        spreadsheetId,
        setSpreadsheetId,
        activityRange,
        setActivityRange,
        legendRange,
        setLegendRange,
        bodyRange,
        setBodyRange,
        name,
        setName,
        birthDate,
        setBirthDate,
        gender,
        setGender,
        schemaWidth,
        setSchemaWidth,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
