import React from 'react';
import 'twin.macro';

const Progress = ({ ...props }) => (
  <div
    tw="relative h-2 overflow-hidden bg-green-600"
    css={`
      @keyframes shimmer {
        100% {
          transform: translateX(0%);
          opacity: 0;
        }
      }

      ::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(233, 233, 233, 1) 0,
          rgba(233, 233, 233, 0.9) 50%,
          rgba(233, 233, 233, 0.8) 100%
        );
        animation: shimmer 2.5s ease-out infinite;
        content: '';
      }
    `}
    {...props}
  ></div>
);

export default Progress;
